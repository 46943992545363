import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-white.svg";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/white/pin.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC2-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC2-P.mp4';

const Story2 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo}/>
                    <StoryHolder color={'white'}>

                        {state.showContent && (
                            <div className={'content-wrapper'}>
                                {!state.showStats && (
                                    <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-white`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>A Parkl alkalmazás
                                                        megjelenik az app
                                                        store-okban és beindul a kommunikáció</h1>
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>Parkl application launches in the app stores and the
                                                        communication begins</h1>
                                                </>
                                            )}
                                        </div>
                                    </Fade>
                                )}
                                {state.showStats && (
                                    <StoryStats color={'white'}/>
                                )}
                            </div>
                        )}
                    </StoryHolder>


                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                    />
                </div>
            </div>
        </>
    );
}

export default Story2;