import './Story.scss';
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import logo from "../../assets/logo/parkl-logo-green.svg";
import {StoryContext} from "../../App";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const Story20 = () => {

    const {state} = useContext(StoryContext);

    useShowContent(state.showContentShortDelay);

    return (
        <div className={`bg-black container`}>
            <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                <Pager logo={logo} showStats={false}/>
                <StoryHolder color={'white'}>

                {state.showContent && (
                    <div className={'content-wrapper'}>
                        <Fade bottom={!state.statsShowed}>
                                <div className={`content txt-white`}>
                                    {state.lang === 'hu' && (
                                        <>
                                            <h1>A mobilitás <span className={'txt-green'}> jövője</span>?</h1>
                                            Reméljük, hogy úgy formálódik a jövő ahogy mi is elképzeljük: mindenhol
                                            digitálisan lehet fizetni, parkolni, tölteni. A digitális megoldások
                                            nyújtotta
                                            előnyökkel pedig élhetőbbé tehetjük környezetünket.<br/><br/>
                                            <strong>És mi a <span className={'txt-green avenir'}>Parkl jövője</span>?</strong>
                                            <br/>
                                            Célunk, hogy Magyarországon ha egy autós parkolási
                                            problémával áll szemben, a Parkl legyen az első ami eszébe jut segítségül.
                                        </>
                                    )}
                                    {state.lang !== 'hu' && (
                                        <>
                                            <h1>The <span className={'txt-green'}>future</span> of mobility?</h1>
                                            We hope for a future as we imagine it now: you can park, charge and pay by
                                            your phone anywhere. Through these digitalized solutions, we can also make
                                            our environment more livable.<br/><br/>
                                            <strong>What is the <span
                                                className={'txt-green avenir'}>future of Parkl</span>?</strong>
                                            <br/>
                                            Our goal is to be the first thought and solution of a driver, when a problem
                                            occurs.
                                        </>
                                    )}
                                </div>
                        </Fade>
                    </div>
                )}
                </StoryHolder>

                <div className={'stats'}/>
            </div>
        </div>
    );
}

export default Story20;