import {StoryContext} from "../../App";
import {useContext} from "react";
import {useMediaQuery} from "react-responsive";

// import logo_purple from '../../assets/logo/parkl-logo-purple.svg';
// import logo_green from '../../assets/logo/parkl-logo-green.svg';

const Pager = ({logo, color, initialMargin}) => {
    const {state, dispatch} = useContext(StoryContext);
    const isSmScreen = useMediaQuery({query: '(max-width: 383px)'});

    const getColor = () => {
        switch (color) {
            case 'white':
                return {
                    'txt': 'txt-white',
                    'points': 'points-white',
                    'color': 'white',
                };
            case 'dark':
                return {
                    'txt': 'txt-dark',
                    'points': 'points-black',
                    'color': 'dark',
                };
            // case 'green':
            //     return {
            //         'txt': 'txt-green',
            //         'points': 'points-black',
            //     };
            case 'black':
                return {
                    'txt': 'txt-black',
                    'points': 'points-black',
                    'color': 'dark',
                }
            default:
                return {
                    'txt': 'txt-white',
                    'points': 'points-white',
                    'color': 'white',
                };
        }
    }

    return (
            <div className={`${(state.showStats || initialMargin) ? 'pager pager-stats' : 'pager'} ${getColor().txt}`}>
                <div className={`points ${getColor().points}`}>
                    {isSmScreen && (
                        <span className={`icon icon-arrow ${state.storyNum>1 ? `icon-arrow-left-${getColor().color}`:``}`}
                                           onClick={()=>dispatch({type: 'prevStory'})}
                        />
                    )}
                    <div className={'pager-content'}>
                        <img src={logo} alt={'parkl-logo'} className={'logo'}/>
                        <span className={`pager-content-date`}>
                            <strong>{state.storyContent?.year}</strong>
                            <br/>
                            {state.lang === 'hu' ?
                                state.storyContent?.month : state.storyContent?.month_en}</span>
                    </div>
                    {isSmScreen && (
                        <span className={`icon icon-arrow icon-arrow-right-${getColor().color}`}
                                           onClick={()=>dispatch({type: 'nextStory'})}/>
                    )}
                </div>
            </div>
        // <div className={`pager ${getColor().txt}`}>
        //     <div className={`${getColor().points}`}>
        //         {isSmScreen && (
        //             <span className={`icon icon-arrow ${state.storyNum>1 ? 'icon-arrow-left-white':''}`}
        //                   onClick={showPrev}
        //             />
        //         )}
        //         <div className={'pager-content'}>
        //             <img src={logo} alt={'parkl-logo'} className={'logo'}/>
        //             <span className={`pager-content-date`}><strong>{state.storyContent.year}</strong><br/>{state.storyContent.month}</span>
        //         </div>
        //         {isSmScreen && (
        //             <span className={`icon icon-arrow icon-arrow-right-white`}
        //                   onClick={showNext}/>
        //         )}
        //     </div>
        // </div>
    );
}

export default Pager;