import './Stats.scss';
import {StoryContext} from "../../App";
import {useContext, useEffect, useState} from "react";
import Odometer from 'react-odometerjs'
import './odometer.scss';

const Stats = ({switchIcon, statsIcon, color, disabledOdometer}) => {
    const {state, dispatch} = useContext(StoryContext);
    const [countValue, setCountValue] = useState(0);

    useEffect(() => {
        if (!disabledOdometer) {
            setTimeout(() => {
                setCountValue(state.storyContent.value);
            }, 500);
        }
    }, [disabledOdometer, state.storyContent.value]);


    const getColor = () => {
        switch (color) {
            case 'white':
                return 'txt-white';
            case 'dark':
                return 'txt-dark';
            case 'green':
                return 'txt-green';
            case 'black':
                return 'txt-black';
            default:
                return 'txt-white';
        }
    }

    const toggleStoryStats = () => {
        dispatch({
            type: 'toggleStoryStats',
        })
    }

    return (
        <div className={`stats ${state.showStats ? 'stats-story' : ''} ${getColor()}`}>
            <img src={switchIcon} alt={'switch-icon'} className={'icon'} onClick={toggleStoryStats}/>
            {!state.showStats && (
                <div className={'stats-content'}>

                    <img src={statsIcon} alt={'stats-icon'} className={'stats-icon'}/>
                    <div className={'stats-numbers'}>
                        <strong>
                                <Odometer
                                    format="d"
                                    duration={disabledOdometer ? 0 : 500}
                                    value={disabledOdometer ? state.storyContent.value : countValue}/>
                        </strong>


                        {/*{Array.from(Array(10), (e,i)=>{*/}
                        {/*    return <p key={i} className={storyContent.value>0 ? '' : 'opacity-0'}>*/}
                        {/*        {storyContent.value-10+(i)}</p>*/}
                        {/*})}*/}
                        {/*/!*<p className={storyContent.value>0 ? '' : 'opacity-0'}>{storyContent.value-2}</p>*!/*/}
                        {/*/!*<p className={storyContent.value>0 ? '' : 'opacity-0'}>{storyContent.value-1}</p>*!/*/}
                        {/*<p><strong>{storyContent.value}</strong></p>*/}
                        {/*{Array.from(Array(10), (e,i)=>{*/}
                        {/*    return <p key={i}>{storyContent.value+(i+1)}</p>*/}
                        {/*})}*/}
                        {/*/!*<p>{storyContent.value + 1}</p>*!/*/}
                        {/*/!*<p>{storyContent.value + 2}</p>*!/*/}
                    </div>

                    <span className={'txt-uppercase txt-center'}>
                        {state.lang === 'hu' ?
                            state.storyContent.title : state.storyContent.title_en}
                    </span>
                </div>
            )}

            {state.showStats && (
                <div className={'stats-content'}>
                    <span className={'txt-uppercase subtitle'}>
                        {state.lang === 'hu' ?
                            state.storyContent.subTitle : state.storyContent.subTitle_en}
                    </span>
                </div>
            )}

            <div className={`icon ${state.showStats ? 'stats-helper' : ''}`}/>
        </div>


        // <div className={`${getColor()}`}>
        //     <img src={switchIcon} alt={'switch-icon'} className={'icon'} onClick={toggleStoryStats}/>
        //     {!storyStatsShown && (
        //         <div className={'stats-content'}>
        //
        //             <img src={statsIcon} alt={'stats-icon'} className={'stats-icon'}/>
        //             <div className={'stats-numbers'}>
        //                 <strong>
        //                     <Odometer
        //                         format="d"
        //                         duration={ 1500 }
        //                         value={ countValue }/>
        //                 </strong>
        //
        //
        //                 {/*{Array.from(Array(10), (e,i)=>{*/}
        //                 {/*    return <p key={i} className={storyContent.value>0 ? '' : 'opacity-0'}>*/}
        //                 {/*        {storyContent.value-10+(i)}</p>*/}
        //                 {/*})}*/}
        //                 {/*/!*<p className={storyContent.value>0 ? '' : 'opacity-0'}>{storyContent.value-2}</p>*!/*/}
        //                 {/*/!*<p className={storyContent.value>0 ? '' : 'opacity-0'}>{storyContent.value-1}</p>*!/*/}
        //                 {/*<p><strong>{storyContent.value}</strong></p>*/}
        //                 {/*{Array.from(Array(10), (e,i)=>{*/}
        //                 {/*    return <p key={i}>{storyContent.value+(i+1)}</p>*/}
        //                 {/*})}*/}
        //                 {/*/!*<p>{storyContent.value + 1}</p>*!/*/}
        //                 {/*/!*<p>{storyContent.value + 2}</p>*!/*/}
        //             </div>
        //
        //             <span className={'txt-uppercase'}>{state.storyContent.title}</span>
        //         </div>
        //     )}
        //
        //     {/*{storyStatsShown && (*/}
        //     {/*    <div className={`${storyStatsShown ? '' : 'stats-content'}`}>*/}
        //     {/*        <span className={'txt-uppercase'}>{text}</span>*/}
        //     {/*    </div>*/}
        //     {/*)}*/}
        //
        //     <div className={`icon ${storyStatsShown ? 'stats-helper' : ''}`}/>
        // </div>
    );
}

export default Stats;