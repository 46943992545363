import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/green/stats.svg";
import logo from "../../assets/logo/parkl-logo-green.svg";
import {useMediaQuery} from 'react-responsive'
import {StoryContext} from "../../App";
import StoryHolder from "../../components/story/storyHolder";
import Fade from "react-reveal/Fade";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC1-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC1-P.mp4';

const Story = () => {
    const {state} = useContext(StoryContext);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    useShowContent(state.showContentDelay);
    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch({type: "showContent"});
    //     }, state.showContentDelay);
    // }, [dispatch, state.showContentDelay])


    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo}/>
                    <StoryHolder color={'white'}>

                        {state.showContent && (
                            <div className={'content-wrapper'}>
                                {!state.showStats && (
                                    <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-white`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>A Parkl 2016.06.25-én látta meg a napvilágot Budapest Parking,
                                                        vagyis <span className={'txt-green'}>BUPA</span> néven</h1>
                                                    Végleges nevét a márka- és alkalmazásfejlesztés során nyerte el.
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>Parkl was born on 25 June 2016 under the name of Budapest
                                                        Parking, or <span className={'txt-green'}>BUPA</span> in
                                                        short.
                                                    </h1>
                                                    It has earned its original name during the brand and app development
                                                    process.
                                                </>
                                            )}
                                        </div>
                                    </Fade>
                                )}
                                {state.showStats && (
                                    <StoryStats color={'white'}/>
                                )}
                            </div>
                        )}
                    </StoryHolder>
                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                    />
                </div>
            </div>
        </>
    );

    // return (
    //     <>
    //         <div className={'video-content'}>
    //             <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true}
    //                    className={'video'}/>
    //         </div>
    //         {storyContext.state.showContent && (
    //             <div className={'content-wrapper'}>
    //                 {!storyContext.state.showStats && (
    //                     <Fade>
    //                         {!isSmScreen && (<span
    //                             className={`icon icon-arrow ${storyContext.state.storyNum > 1 ? 'icon-arrow-left-white' : ''}`}
    //                             onClick={storyContext.showPrev}/>)}
    //                         <div className={`content txt-white`}>
    //                             <h1>A Parkl 2016.05.26-án látta meg a napvilágot Budapest Parking Kft,
    //                                 vagyis <span className={'txt-green'}>BUPA</span> néven</h1>
    //                             Végleges nevét a márka- és alkalmazásfejlesztés során nyerte el.
    //                         </div>
    //                         {!isSmScreen && (<span className={`icon icon-arrow icon-arrow-right-white`}
    //                                                onClick={storyContext.showNext}/>)}
    //                     </Fade>
    //                 )}
    //                 {storyContext.state.showStats && (
    //                     <StoryStats statsContent={statsContent}/>
    //                 )}
    //             </div>
    //         )}
    //     </>
    // );
}

export default Story;