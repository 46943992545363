import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-dark.svg";
import switchIcon from "../../assets/icons/switch.svg";
import statsIcon from "../../assets/icons/pin.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";

import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";
import Top300Content from "../../components/story/Top300Content";
import Top10Content from "../../components/story/Top10Content";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC11-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC11-P.mp4';

const Story11 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo} color={'dark'}/>
                    <StoryHolder color={'dark'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-dark`}>
                                            {state.personalizedContent.b2c && !state.personalizedContent.top10 && (
                                                <Top300Content lang={state.lang}/>
                                            )}
                                            {state.personalizedContent.b2c && state.personalizedContent.top10 && (
                                                <Top10Content lang={state.lang} content={state.personalizedContent}/>
                                            )}
                                        </div>
                                </Fade>
                            )}
                            {state.showStats && (
                                <StoryStats color={'dark'}/>
                            )}
                        </div>
                    )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                        color={'dark'}
                    />
                </div>
            </div>
        </>
    );
}

export default Story11;