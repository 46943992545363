import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/green/stats.svg";
import logo from "../../assets/logo/parkl-logo-green.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC7-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC7-P.mp4';

const Story7 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});
    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo}/>
                    <StoryHolder color={'white'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-white`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>Közben már <br/><span
                                                        className={'txt-green'}>14 ember dolgozik</span> a
                                                        következő nagy durranáson</h1>
                                                    Ők a Parkl szíve, nélkülük ez a szolgáltatás
                                                    nem lenne az, ami.
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>Meanwhile, <br/><span className={'txt-green'}>already 14 people are working </span>
                                                        on our next big shot.</h1>
                                                    They are the essence of Parkl, our service wouldn’t be the same
                                                    without them.
                                                </>
                                            )}

                                        </div>
                                </Fade>
                            )}
                            {state.showStats && (
                                <StoryStats color={'white'}/>
                            )}
                        </div>
                    )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                    />
                </div>
            </div>
        </>
    );
}

export default Story7;