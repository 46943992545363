import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-purple.svg";
import switchIcon from "../../assets/icons/switch.svg";
import statsIcon from "../../assets/icons/purple/pin.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC15-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC15-P.mp4';

const Story15 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});
    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo} color={'dark'}/>
                    <StoryHolder color={'dark'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-dark`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>Újratervezés</h1>
                                                    A járványhelyzet alatt elrendelt intézkedések miatt drasztikusan
                                                    lecsökkentek a tranzakciós számaink, emiatt kénytelenek voltunk a
                                                    stratégiánkat újragondolni.
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>Redesigning</h1>
                                                    Due to state measures introduced because of coronavirus, our number
                                                    of transactions dropped drastically. We needed to rethink our
                                                    strategy.
                                                </>
                                            )}

                                        </div>
                                </Fade>
                            )}
                            {state.showStats && (
                                <StoryStats color={'dark'}/>
                            )}
                        </div>
                    )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                        color={'dark'}
                    />
                </div>
            </div>
        </>
    );
}

export default Story15;