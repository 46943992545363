import './main.scss';
import logo from '../../assets/logo/parkl-logo-green.svg';
import {useContext} from "react";
import {StoryContext} from "../../App";

const Main = () => {
    const {state, dispatch} = useContext(StoryContext);

    return (
        <div className={`bg bg-black`}>
            <div className={`wrapper flex-col flex-align-center`}>
                <div className={`content content-main txt-white h-100`}>
                    <img src={logo} alt={'parkl-logo'} className={'logo'}/>
                    {state.lang === 'hu' && (
                        <>
                            <h1 className={'header-main'}><span className={'txt-green'}>Szervusz,</span><br/> az én nevem<br/>
                                Somogyi Zsolt.</h1>
                            Engedd meg, hogy bemutassuk neked a Parkl elmúlt 5 évét.<br/>
                            Foglalj helyet és kösd be magad.
                            <button className={'btn'} onClick={()=>dispatch({type: 'nextStory'})}>
                                Indulás
                            </button>
                        </>
                    )}
                    {state.lang !== 'hu' && (
                        <>
                            <h1 className={'header-main'}><span className={'txt-green'}>Hello,</span><br/> my name is<br/>
                                Zsolt Somogyi.</h1>
                            Let us introduce you the first 5 years of Parkl.<br/>
                            Take a seat and fasten your seatbelt.
                            <button className={'btn'} onClick={()=>dispatch({type: 'nextStory'})}>
                                Let's go!
                            </button>
                        </>
                    )}

                    <p className={'lang'} onClick={()=>dispatch({type: 'langChange'})}>{state.lang==="hu" ? 'English': 'Magyar'}</p>
                </div>

            </div>
        </div>

    )
}

export default Main;