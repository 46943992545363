import {useContext, useEffect, useState} from "react";
import Slideshow from "../Slideshow";
import {StoryContext} from "../../App";

const ForPartners = () => {
    const {dispatch} = useContext(StoryContext);
    const [loaded, setLoaded] = useState(false);
    useEffect(()=>{
        dispatch({type: 'setPartnerSlides'});
        setLoaded(true);
    }, [dispatch])

    return (
        <>
            {loaded && (
                <>
                    <Slideshow/>
                </>
            )}
        </>
    )
}
export default ForPartners;