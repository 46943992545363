import logo from '../../assets/logo/parkl-logo-purple.svg'
import fblogo from '../../assets/icons/facebook.svg'
import instalogo from '../../assets/icons/instagram.svg'
import chainlogo from '../../assets/icons/link.svg'
import restartLogo from '../../assets/icons/redo-alt-solid.svg'
import './Share.scss';
import {useContext} from "react";
import {StoryContext} from "../../App";

const Share = () => {
    const {state, dispatch} = useContext(StoryContext);

    return (
        <div className={`bg-light h-100`}>
            <div className={`wrapper-share`}>
                <div className={'share-restart share-parkl-logo'}>
                    <img src={logo} alt={'parkl-logo'} className={'logo'} onClick={() => dispatch({type: 'reset'})}/>
                </div>
                <div className={`content content-share txt-black txt-center flex-align-center`}>
                    {state.lang === 'hu' && (
                        <h1>Köszönjük,<br/> hogy része <br/> voltál az első <br/> <span className={'txt-purple'}>öt évünknek</span>!
                        </h1>
                    )}
                    {state.lang !== 'hu' && (
                        <h1>Thank you for being part of our first <span className={'txt-purple'}>five years</span>!
                        </h1>
                    )}
                    <div className={'share-restart mt-0'}>
                        <img src={restartLogo} className={'logo'} alt={''} onClick={() => dispatch({type: 'reset'})}/>
                    </div>
                </div>
                

                <div className={`pager-share`}>
                    {state.lang === 'hu' && (
                        <h3 className={'txt-uppercase'}>Oszd meg másokkal</h3>
                    )}
                    {state.lang !== 'hu' && (
                        <h3 className={'txt-uppercase'}>Share with others</h3>
                    )}
                    <div className={'share-logo-holder'}>

                        <a href={'https://www.facebook.com/parklapp'} target={'_blank'} rel={'noopener noreferrer'}>
                                <img src={fblogo} alt={'logo'} className={'logo'}/>
                        </a>


                        <a href={'https://instagram.com/parklapp'} target={'_blank'} rel={'noopener noreferrer'}>
                                <img src={instalogo} alt={'logo'} className={'logo'}/>
                        </a>

                        <a href={'https://parkl.net'} target={'_blank'} rel={'noopener noreferrer'}>
                                <img src={chainlogo} alt={'logo'} className={'logo'}/>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Share;