import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-white.svg";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/white/pin.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC5-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC5-P.mp4';

const Story5 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>
                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo}/>
                    <StoryHolder color={'white'}>
                        {state.showContent && (
                            <div className={'content-wrapper'}>
                                {!state.showStats && (
                                    <>
                                        {!state.personalizedContent.b2c && (
                                            <Fade bottom={!state.statsShowed}>
                                                <div className={`content txt-white`}>
                                                    {state.lang === 'hu' && (
                                                        <>
                                                            <h1>Itt csatlakoztál be
                                                                Te a Parkl világba</h1>
                                                            Ezúton is szeretnénk megköszönni, hogy
                                                            bizalmat szavaztál nekünk és reméljük, hogy a
                                                            jövőben még több olyan hasznos
                                                            szolgáltatással szolgálhatunk, amelyek
                                                            hozzájárulnak vállalkozásod sikereihez.
                                                        </>
                                                    )}
                                                    {state.lang !== 'hu' && (
                                                        <>
                                                            <h1>This is where you have joined the world of Parkl.</h1>
                                                            Thank you for your trust, and we hope that our solutions
                                                            will
                                                            continue to make urban mobility even easier for you in the
                                                            future.
                                                        </>
                                                    )}
                                                </div>
                                            </Fade>
                                        )}
                                        {state.personalizedContent.b2c && (
                                            <Fade bottom={!state.statsShowed}>
                                                <div className={`content txt-white`}>
                                                    {state.lang === 'hu' && (
                                                        <>
                                                            <h1>Itt csatlakoztál be
                                                                Te a Parkl világba</h1>
                                                            Ezúton is szeretnénk megköszönni, hogy bizalmat szavaztál
                                                            nekünk
                                                            és
                                                            reméljük, hogy a jövőben szolgáltatásainkkal még egyszerűbbé
                                                            tehetjük számodra a városi közlekedést.
                                                        </>
                                                    )}
                                                    {state.lang !== 'hu' && (
                                                        <>
                                                            <h1>This is where you have joined the world of Parkl.</h1>
                                                            Thank you for your trust, we hope that our solutions will
                                                            contribute
                                                            to the success of your business in the future too.
                                                        </>
                                                    )}
                                                </div>
                                            </Fade>
                                        )}
                                    </>
                                )}
                                {state.showStats && (
                                    <StoryStats color={'white'}/>
                                )}
                            </div>
                        )}
                    </StoryHolder>
                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                    />
                </div>
            </div>
        </>
    );
}

export default Story5;