import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/green/stats.svg";
import logo from "../../assets/logo/parkl-logo-green.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC17-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC17-P.mp4';

const Story17 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo}/>
                    <StoryHolder color={'white'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-white`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>Hello <span className={'txt-green'}>Liget</span>!</h1>
                                                    Büszkék vagyunk rá, hogy az ország egyik legmodernebb mélygarázsát,
                                                    a
                                                    városligeti Múzeum Mélygarázst is okossá varázsolhattuk és az itt
                                                    található
                                                    óriási töltősziget is felkerülhetett a Parkl e-mobilitás térképére.
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>Hello <span className={'txt-green'}>Liget</span>!</h1>
                                                    We are proud to be the digital parking service provider of the most
                                                    modern underground garage in the country, and that the huge EV
                                                    charging island located here could also be added to Parkl's
                                                    e-mobility map.
                                                </>
                                            )}

                                        </div>
                                </Fade>
                            )}
                            {state.showStats && (
                                <StoryStats color={'white'}/>
                            )}
                        </div>
                    )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                    />
                </div>
            </div>
        </>
    );
}

export default Story17;