import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/green/stats.svg";
import logo from "../../assets/logo/parkl-logo-green.svg";
import park_green from "../../assets/icons/green/park.svg";
import bolt_green from "../../assets/icons/green/bolt.svg";
import calendar from "../../assets/icons/green/calendar.svg";
import {StoryContext} from "../../App";

import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const Story16 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentShortDelay);

    const b2bStats = {
        'office': 5,
        'chargePoint': 200,
        'chargeNetw': 3,
    }

    return (
        <div className={`bg-black container`}>
            <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                <Pager logo={logo}/>
                <StoryHolder color={'white'}>

                {state.showContent && (
                    <div className={'content-wrapper'}>
                        {!state.showStats && (
                            <Fade bottom={!state.statsShowed}>
                                    <div className={`content txt-white`}>
                                        {state.lang === 'hu' &&(
                                            <>
                                                <h1>B2B fókusz</h1>
                                                <div className={'card-container txt-dark'}>
                                                    <Fade delay={1000}>
                                                        <div className={`card flex flex-align-center`}>
                                                            <img src={calendar} alt={'calendar-logo'}/>
                                                            <div className={`flex flex-col`}>
                                                                <h2>{b2bStats.office}.</h2>
                                                                iroda csatlakozik
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade delay={1500}>
                                                        <div className={`card flex flex-align-center`}>
                                                            <img src={park_green} alt={'calendar-logo'}/>
                                                            <div className={`flex flex-col`}>
                                                                <h2>{b2bStats.chargePoint}.</h2>
                                                                töltőpontunk is felkerült a térképre
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade delay={2000}>
                                                        <div className={`card flex flex-align-center`}>
                                                            <img src={bolt_green} alt={'calendar-logo'}/>
                                                            <div className={`flex flex-col`}>
                                                                <h2>{b2bStats.chargeNetw}.</h2>
                                                                legkiterjedtebb töltőhálózat már a Parklé
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </>
                                        )}
                                        {state.lang !== 'hu' &&(
                                            <>
                                                <h1>B2B focus</h1>
                                                <div className={'card-container txt-dark'}>
                                                    <Fade delay={1000}>
                                                        <div className={`card flex flex-align-center`}>
                                                            <img src={calendar} alt={'calendar-logo'}/>
                                                            <div className={`flex flex-col`}>
                                                                <h2>{b2bStats.office}.</h2>
                                                                office building implements Parkl Office
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade delay={1500}>
                                                        <div className={`card flex flex-align-center`}>
                                                            <img src={park_green} alt={'calendar-logo'}/>
                                                            <div className={`flex flex-col`}>
                                                                <h2>{b2bStats.chargePoint}.</h2>
                                                                charging station operated by Parkl
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                    <Fade delay={2000}>
                                                        <div className={`card flex flex-align-center`}>
                                                            <img src={bolt_green} alt={'calendar-logo'}/>
                                                            <div className={`flex flex-col`}>
                                                                <h2>{b2bStats.chargeNetw}.</h2>
                                                                biggest e-mobility network belongs to Parkl
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </>
                                        )}
                                    </div>
                            </Fade>
                        )}
                        {state.showStats && (
                            <StoryStats color={'white'}/>
                        )}
                    </div>
                )}
                </StoryHolder>

                <Stats
                    switchIcon={switchIcon}
                    statsIcon={statsIcon}
                />
            </div>
        </div>
    );
}

export default Story16;