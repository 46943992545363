import {Redirect, useParams} from "react-router-dom";
import top10 from '../data/top10.json';
import top20partner from '../data/top20partner.json';
import {useContext, useEffect, useState} from "react";
import {StoryContext} from "../App";
import Slideshow from "./Slideshow";

const CustomStories = () => {
    const {dispatch} = useContext(StoryContext);

    const [loaded, setLoaded] = useState(false);
    const [validId, setValidId] = useState(false);

    let {id} = useParams();

    useEffect(() => {
        if (top10[id] !== null && top10[id] !== undefined) {
            setValidId(true);
            dispatch({type: "setCustomSlide", payload: top10[id]});
        } else if (top20partner[id] !== null && top20partner[id] !== undefined) {
            setValidId(true);
            dispatch({type: "setPartnerSlides", payload: top20partner[id]});
        }
        setLoaded(true);
    }, [dispatch, id]);

    return (
        <>
            {loaded && (
                <>
                    {validId && (
                        <Slideshow/>
                    )}
                    {!validId && (
                        <Redirect to={'/'}/>
                    )}
                </>
            )}
        </>
    );
}
export default CustomStories;