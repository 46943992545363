/* eslint-disable */

import {StoryContext} from "../App";
import {useContext, useEffect} from "react";

const useShowContent = (showContentDelay) => {
    const {state, dispatch} = useContext(StoryContext);

    useEffect(() => {
        if (!state.showContent) {
            setTimeout(() => {
                dispatch({type: "showContent"});
            }, showContentDelay);
        }
    }, [])
}

export default useShowContent;

