import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext, useEffect, useState} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import {StoryContext} from "../../App";
import Fade from "react-reveal/Fade";
import useShowContent from "../../components/useShowContent";
import logo from '../../assets/logo/parkl-logo-white.svg';
import logo_dark from '../../assets/logo/parkl-logo-dark.svg';
import statsIcon from '../../assets/icons/white/stats.svg';
import statsIcon_dark from '../../assets/icons/stats.svg';
import switchIcon from '../../assets/icons/white/switch.svg';
import switchIcon_dark from '../../assets/icons/switch.svg';
import boltIcon from '../../assets/icons/bolt.svg';
import fleetIcon from '../../assets/icons/fleet.svg';
import streetIcon from '../../assets/icons/streetparking.svg';
import parkingIcon from '../../assets/icons/offstreetparking.svg';
import officeIcon from '../../assets/icons/office.svg';
import sharedmobilityIcon from '../../assets/icons/sharedmobility.svg';
import micromobilityIcon from '../../assets/icons/micromobility.svg';
import analyticsIcon from '../../assets/icons/analytics.svg';
import digitalparkingIcon from '../../assets/icons/digitalparking.svg';
import connectivityIcon from '../../assets/icons/connectivity.svg';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import StoryHolder from "../../components/story/storyHolder";

const Story19 = () => {
    const {state} = useContext(StoryContext);

    const [currentSlide, setCurrentSlide] = useState(0);

    useShowContent(500);

    const slidesB2C = [
        <div className={`bg-purple container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    {!state.showStats && (
                        <div className={`content txt-white txt-center flex-align-center`}>
                            {state.lang === 'hu' && (
                                <h1>A Parkl mára hatalmas utat járt be</h1>
                            )}
                            {state.lang !== 'hu' && (
                                <h1>Parkl has come a long way.</h1>
                            )}
                        </div>
                    )}
                    {state.showStats && (
                        <StoryStats color={'white'}/>
                    )}
                </div>
                <Stats statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-purple-dark container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Zárt téri parkolás</h1>
                                <img src={parkingIcon} alt={''}/>
                                Sorompónyitás és fizetés kényelmesen, mobillal.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Off-street parking</h1>
                                <img src={parkingIcon} alt={''}/>
                                Convenient barrier opening and payment with mobile.
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-purple container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>

                                <h1>Utcai parkolás</h1>
                                <img src={streetIcon} alt={''}/>
                                Parkolás közterületen zónavadászat és aprópénz nélkül.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>On-street parking</h1>
                                <img src={streetIcon} alt={''}/>
                                Cashless on-street parking with automatic zone-checking.
                            </div>
                        )}
                    </div>
                </div>
                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-green container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo_dark} color={'black'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-black txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Elektromos töltés</h1>
                                <img src={boltIcon} alt={''}/>
                                Töltőpontok keresése, indítás és fizetés egy appban.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Electric charging</h1>
                                <img src={boltIcon} alt={''}/>
                                Finding, starting and paying EV charging easily with mobile.
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon_dark} switchIcon={switchIcon_dark}
                       color={'black'}/>
            </div>
        </div>,
        <div className={`bg-yellow container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo_dark} color={'dark'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-black txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Parkl FLEET</h1>
                                <img src={fleetIcon} alt={''}/>
                                Céges flotta parkolások és töltések kezelése egy felületen.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Parkl FLEET</h1>
                                <img src={fleetIcon} alt={''}/>
                                Management of corporate fleet parkings and chargings in one app.
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon_dark} switchIcon={switchIcon_dark}
                       color={"dark"}/>
            </div>
        </div>,
        <div className={`bg-error container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Parkl OFFICE</h1>
                                <img src={officeIcon} alt={''}/>
                                Hatékony, digitális irodai parkolás kezelés.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Parkl OFFICE</h1>
                                <img src={officeIcon} alt={''}/>
                                Efficient digital office parking management.
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-white container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo_dark} color={'black'} initialMargin={true}/>
                <StoryHolder color={'dark'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <div className={`content txt-black txt-center flex-align-center`}>
                                    <div className={'story19-content zoom-animation'}>
                                        <div className={'story19-content-last'}>
                                            <div/>
                                            <div className={'bg-error flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={officeIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Parkl OFFICE</span>
                                                </div>
                                            </div>
                                            <div/>
                                            <div className={'bg-purple flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={streetIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Utcai parkolás</span>
                                                </div>
                                            </div>
                                            <div/>
                                            <div className={'bg-yellow flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={fleetIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span>Parkl FLEET</span>
                                                </div>
                                            </div>
                                            <div className={'bg-purple-dark flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={parkingIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Zárt téri parkolás</span>
                                                </div>
                                            </div>
                                            <div className={'bg-green flip-card flip-animation'}>
                                                <div className={'card-front'}>
                                                    <img src={boltIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span>Elektromos töltés</span>
                                                </div>
                                            </div>
                                            <div/>
                                            <div>
                                                <div className={'triangle-up-left'}/>
                                            </div>
                                            <div/>
                                            <div/>

                                        </div>
                                        <h1>PARKL 2021</h1>
                                    </div>
                                </div>
                            )}
                            {state.showStats && (
                                <StoryStats color={'black'}/>
                            )}
                        </div>
                    )}
                </StoryHolder>

                <Stats disabledOdometer={true} statsIcon={statsIcon_dark} switchIcon={switchIcon_dark}
                       color={'black'}/>
            </div>
        </div>
    ]

    const slidesB2B = [
        <div className={`bg-purple container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <Fade bottom={true}>
                    <div className={'flex'}>
                        {!state.showStats && (
                            <div className={`content txt-white txt-center flex-align-center`}>
                                {state.lang === 'hu' && (
                                    <h1>A Parkl mára hatalmas utat járt be</h1>
                                )}
                                {state.lang !== 'hu' && (
                                    <h1>Parkl has come a long way.</h1>
                                )}
                            </div>
                        )}
                        {state.showStats && (
                            <StoryStats color={'white'}/>
                        )}
                    </div>
                </Fade>
                <Stats statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-yellow container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo_dark} color={'dark'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-black txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Shared mobility</h1>
                                <img src={sharedmobilityIcon} alt={''}/>
                                Közös megoldások a közösségi autómegosztó cégekkel.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Shared mobility</h1>
                                <img src={fleetIcon} alt={''}/>
                                Common solutions with carsharing companies.
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon_dark} switchIcon={switchIcon_dark}
                       color={"dark"}/>
            </div>
        </div>,
        <div className={`bg-purple-dark container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Data analytics</h1>
                                <img src={analyticsIcon} alt={''}/>
                                A rendszerünk által kezelt adatok átfogó elemzése és biztosítása.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Data analytics</h1>
                                <img src={parkingIcon} alt={''}/>
                                Comprehensive analysis and provision of data managed by our system.
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-error container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Micromobility</h1>
                                <img src={micromobilityIcon} alt={''}/>
                                Megoldások környezettudatos közlekedést támogató partnerek számára, mint az okos
                                kerékpár vagy a roller tároló
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Micromobility</h1>
                                <img src={officeIcon} alt={''}/>
                                Solutions for partners supporting sustainable mobility (e.g. smart bike and scooter storage)
                            </div>
                        )}
                    </div>

                </div>

                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-purple container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Digital parking</h1>
                                <img src={digitalparkingIcon} alt={''}/>
                                Digitális utcai és zárt téri parkolás applikációval
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Digital parking</h1>
                                <img src={streetIcon} alt={''}/>
                                Digital on and off-street parking in one app.
                            </div>
                        )}
                    </div>
                </div>
                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-purple-darker container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo} color={'white'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-white txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Connectivity</h1>
                                <img src={connectivityIcon} alt={''}/>
                                Parkolás és elektromos töltés digitalizációja, egyedi szoftveres integrációval
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>Connectivity</h1>
                                <img src={connectivityIcon} alt={''}/>
                                Digitalization of parking and electric charging with unique software integration.
                            </div>
                        )}
                    </div>
                </div>
                <Stats disabledOdometer={true} statsIcon={statsIcon} switchIcon={switchIcon}
                       color={'white'}/>
            </div>
        </div>,
        <div className={`bg-green container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo_dark} color={'black'} initialMargin={true}/>
                <div className={'flex'}>
                    <div className={`content txt-black txt-center flex-align-center`}>
                        {state.lang === 'hu' && (
                            <div className={'story19-content'}>
                                <h1>E-mobility</h1>
                                <img src={boltIcon} alt={''}/>
                                Elektromos töltő üzemeltetés és fizetési szolgáltatás.
                            </div>
                        )}
                        {state.lang !== 'hu' && (
                            <div className={'story19-content'}>
                                <h1>E-mobility</h1>
                                <img src={boltIcon} alt={''}/>
                                Electric charger operation and payment services.
                            </div>
                        )}
                    </div>
                </div>
                <Stats disabledOdometer={true} statsIcon={statsIcon_dark} switchIcon={switchIcon_dark}
                       color={'black'}/>
            </div>
        </div>,
        <div className={`bg-white container`}>
            <div className={`wrapper wrapper-stats`}>
                <Pager logo={logo_dark} color={'black'} initialMargin={true}/>
                <StoryHolder>

                {state.showContent && (
                    <div className={'content-wrapper'}>
                        {!state.showStats && (
                                <div className={`content txt-black txt-center flex-align-center`}>
                                    <div className={'story19-content zoom-animation'}>
                                        <div className={'story19-content-last'}>
                                            <div/>
                                            <div className={'bg-error flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={micromobilityIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Micromobility</span>
                                                </div>
                                            </div>
                                            <div className={'bg-purple-darker flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={connectivityIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Connectivity</span>
                                                </div>
                                            </div>
                                            <div className={'bg-purple flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={digitalparkingIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Digital parking</span>
                                                </div>
                                            </div>
                                            <div/>
                                            <div className={'bg-yellow flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={sharedmobilityIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span>Shared mobility</span>
                                                </div>
                                            </div>
                                            <div className={'bg-purple-dark flip-card'}>
                                                <div className={'card-front'}>
                                                    <img src={analyticsIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span className={'txt-white'}>Data analytics</span>
                                                </div>
                                            </div>
                                            <div className={'bg-green flip-card flip-animation'}>
                                                <div className={'card-front'}>
                                                    <img src={boltIcon} alt={''}/>
                                                </div>
                                                <div className={'card-back'}>
                                                    <span>E-mobility</span>
                                                </div>
                                            </div>
                                            <div/>
                                            <div>
                                                <div className={'triangle-up-left'}/>
                                            </div>
                                            <div/>
                                            <div/>
                                        </div>
                                        <h1>PARKL 2021</h1>
                                    </div>
                                </div>

                        )}
                        {state.showStats && (
                            <StoryStats color={'black'}/>
                        )}
                    </div>
                )}
                    </StoryHolder>
                <Stats disabledOdometer={true} statsIcon={statsIcon_dark} switchIcon={switchIcon_dark}
                       color={'black'}/>
            </div>
        </div>
    ]

    useEffect(() => {
        if (state.showContent) {
            currentSlide < (state.personalizedContent.b2c? (slidesB2C.length - 1) : (slidesB2B.length -1)) &&
            setTimeout(() => setCurrentSlide(currentSlide + 1), 1500);
        }
        // eslint-disable-next-line
    }, [currentSlide, state.personalizedContent.b2c, state.showContent]);

    return (
        <TransitionGroup className={`overflow-hidden h-100 w-100`}>
            <CSSTransition
                key={currentSlide}
                timeout={1000}
                classNames={'my-node'}
                unmountOnExit
            >
                <div className={'h-100 w-100'}>
                    {state.personalizedContent.b2c ? slidesB2C[currentSlide] : slidesB2B[currentSlide]}
                </div>
            </CSSTransition>

        </TransitionGroup>



        // <div className={`bg-purple container`}>
        //     <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
        //         <Pager logo={logo}/>
        //         {state.showContent && (
        //             <div className={'content-wrapper'}>
        //                 {!state.showStats && (
        //                     //                         <StoryHolder color={'white'}>
        //                             <div className={`content txt-white`}>
        //                                 {state.lang === 'hu' && (
        //                                     <h1>A Parkl mára hatalmas utat járt be</h1>
        //                                 )}
        //                                 {state.lang !== 'hu' && (
        //                                     <h1>Parkl has come a long way.</h1>
        //                                 )}
        //                             </div>
        //                         </StoryHolder>
        //                     </Fade>
        //                 )}
        //                 {state.showStats && (
        //                     <StoryStats color={color}/>
        //                 )}
        //             </div>
        //         )}
        //
        //         <Stats
        //             text={color.text}
        //             switchIcon={switchIcon}
        //             statsIcon={statsIcon}
        //         />
        //     </div>
        // </div>
    );
}

export default Story19;