const storyContents = [
    // 0 (main screen)
    {},
    // 1
    {
        year: 2016,
        month: 'Június',
        month_en: 'June',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: 'Napvilágot lát a Parkl',
        subTitle_en: 'Parkl was born',
        value: 0,
        users: 0,
        chargers: 0,
        positions: 0,
        fleet: 0,
    },
    // 2
    {
        year: 2017,
        month: 'Április',
        month_en: 'April',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: 'Megjelenik a Parkl alkalmazás',
        subTitle_en: 'Parkl app appears',
        value: 230,
        users: 230,
        chargers: 0,
        positions: 7,
        fleet: 0,
    },
    // 3
    {
        year: 2017,
        month: 'December',
        month_en: 'December',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: 'A fogadtatás jól sikerült',
        subTitle_en: 'It was warmly welcome',
        value: 2515,
        users: 2515,
        chargers: 0,
        positions: 16,
        fleet: 0,
    },
    // 4
    {
        year: 2018,
        month: 'Február',
        month_en: 'February',
        title: 'Lokáció',
        title_en: 'Location',
        subTitle: 'Már 20 zárt téri partnerünk van',
        subTitle_en: 'The number of our parking locations exceeds twenty',
        value: 20,
        users: 4473,
        chargers: 0,
        positions: 20,
        fleet: 0,
    },
    // 5
    {
        year: 2018,
        month: 'Május',
        month_en: 'May',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: '',
        subTitle_en: '',
        value: 0,
        users: 0,
        chargers: 0,
        positions: 0,
        fleet: 0,
    },
    // 6
    {
        year: 2018,
        month: 'Május',
        month_en: 'May',
        title: 'Lokáció',
        title_en: 'Location',
        subTitle: 'Az első instant parkolás',
        subTitle_en: 'The first instant parking',
        value: 23,
        users: 9225,
        chargers: 0,
        positions: 23,
        fleet: 0,
    },
    // 7
    {
        year: 2018,
        month: 'Július',
        month_en: 'July',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: 'A Parkl csapat 14 fősre nőtt',
        subTitle_en: 'The parkl team is now consists of 14 people',
        value: 14215,
        users: 14215,
        chargers: 0,
        positions: 28,
        fleet: 0,
    },
    // 8
    {
        year: 2018,
        month: 'Október',
        month_en: 'October',
        title: 'Töltőpont',
        title_en: 'Charge point',
        subTitle: 'Az első elektromos töltőállomás',
        subTitle_en: 'The first electric charging station',
        value: 10,
        users: 18267,
        chargers: 10,
        positions: 33,
        fleet: 0,
    },
    // 9
    {
        year: 2019,
        month: 'Április',
        month_en: 'April',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: 'Elindul a közterületi parkolás',
        subTitle_en: 'On-street parking launches',
        value: 35165,
        users: 35165,
        chargers: 35,
        positions: 46,
        fleet: 0,
    },
    // 10
    {
        year: 2019,
        month: 'Október',
        month_en: 'October',
        title: 'Parkl Business tag',
        title_en: 'Parkl Business member',
        subTitle: 'A Parkl Business bemutatkozik',
        subTitle_en: 'Parkl Business introducing',
        value: 5,
        users: 41200,
        chargers: 48,
        positions: 57,
        fleet: 5,
    },
    // 11
    {
        year: 2019,
        month: 'Május',
        month_en: 'May',
        title: 'Lokáció',
        title_en: 'Location',
        subTitle: '',
        subTitle_en: '',
        value: 62,
        users: 47091,
        chargers: 60,
        positions: 62,
        fleet: 6,
    },
    // 12
    {
        year: 2019,
        month: 'December',
        month_en: 'December',
        title: 'Lokáció',
        title_en: 'Location',
        subTitle: 'Hello bevásárlóközpontok!',
        subTitle_en: 'Hello shopping centers!',
        value: 62,
        users: 47091,
        chargers: 60,
        positions: 62,
        fleet: 6,
    },
    // 13
    {
        year: 2020,
        month: 'Február',
        month_en: 'February',
        title: 'Parkl Business tag',
        title_en: 'Parkl Business member',
        subTitle: 'A Parkl az év proptech megoldása',
        subTitle_en: 'Parkl – Proptech solution of the year',
        value: 15,
        users: 51446,
        chargers: 62,
        positions: 68,
        fleet: 15,
    },
    // 14
    {
        year: 2020,
        month: 'Március',
        month_en: 'March',
        title: 'Parkl Business tag',
        title_en: 'Parkl Business member',
        subTitle: 'De aztán…',
        subTitle_en: 'But then...',
        value: 19,
        users: 53301,
        chargers: 62,
        positions: 68,
        fleet: 19,
    },
    // 15
    {
        year: 2020,
        month: 'Május',
        month_en: 'May',
        title: 'Parkl Business tag',
        title_en: 'Parkl Business member',
        subTitle: 'Újratervezés',
        subTitle_en: 'Redesigning',
        value: 27,
        users: 53623,
        chargers: 67,
        positions: 68,
        fleet: 27,
    },
    // 16
    {
        year: 2020,
        month: 'Október',
        month_en: 'October',
        title: 'Töltőpont',
        title_en: 'Charge point',
        subTitle: 'B2B fókusz',
        subTitle_en: 'B2B focus',
        value: 200,
        users: 62357,
        chargers: 200,
        positions: 70,
        fleet: 37,
    },
    // 17
    {
        year: 2020,
        month: 'December',
        month_en: 'December',
        title: 'Lokáció',
        title_en: 'Location',
        subTitle: 'Hello Liget!',
        subTitle_en: 'Hello Liget!',
        value: 74,
        users: 66470,
        chargers: 200,
        positions: 74,
        fleet: 40,
    },
    // 18
    {
        year: 2021,
        month: 'Február',
        month_en: 'February',
        title: 'Lokáció',
        title_en: 'Location',
        subTitle: 'Hello KÖKI!',
        subTitle_en: 'Hello KÖKI!',
        value: 76,
        users: 67412,
        chargers: 204,
        positions: 76,
        fleet: 44,
    },
    // 19
    {
        year: 2021,
        month: 'Május',
        month_en: 'May',
        title: 'Felhasználó',
        title_en: 'Users',
        subTitle: 'A Parkl mára hatalmas utat járt be',
        subTitle_en: 'Parkl has come a long way',
        value: 73193,
        users: 73193,
        chargers: 210,
        positions: 79,
        fleet: 49,
    },
    // 20
    {
        year: 2030,
        month: 'Január',
        month_en: 'January',
        subTitle: '',
        subTitle_en: '',
        title_en: '',
        title: '',
        value: 0,
        users: 0,
        chargers: 0,
        positions: 0,
        fleet: 0,
    },
]

export default storyContents;