import React, {createContext, useEffect, useMemo, useReducer} from 'react';
import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import storyContents from "./data/data";
import CustomStories from "./components/CustomStories";
import Slideshow from "./components/Slideshow";
import useKeyPress from "./components/useKeyPress";
import Top300 from "./components/story/Top300";
import ForPartners from "./components/story/ForPartners";

const slides = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 12, 14, 15, 17, 18, 19, 20, 21];
//              0, 1, 2, 3, 4, 5, 6, 7, 8,  9, 10, 11, 12, 13, 14, 15, 16, 17

//              0, 1, 2, 4, 6, 7, 8, 9, 10, 12, 14, 15, 17, 18, 19, 20, 21
//              0, 1, 2, 3, 4, 5, 6, 7,  8,  9, 10, 11, 12, 13, 14, 15, 16, 17

//              0, 1, 2, 4, 6, 7, 8, 9, 10, 12, 13, 14, 15, 17, 18, 19, 20, 21
//              0, 1, 2, 3, 4, 5, 6, 7,  8,  9, 10, 11, 12, 13, 14, 15, 16, 17

const setCustomSlide = (payload) => {
    storyContents[5].year = payload.registerYear;
    storyContents[5].month = payload.registerMonth;
    storyContents[5].month_en = payload.registerMonth_en;
    storyContents[5].value = payload.users;
    storyContents[5].users = payload.users;
    storyContents[5].chargers = payload.chargePoints;
    storyContents[5].positions = payload.parkingLots;
    storyContents[5].fleet = payload.parklBusinessMembers;
}

const reducer = (state, action) => {
    let currentStoryNum = 0;
    switch (action.type) {
        case "langChange":
            return {
                ...state,
                lang: state.lang === 'hu' ? 'en' : 'hu',
            }
        case "toggleStoryStats":
            return {
                ...state,
                showStats: !state.showStats,
                statsShowed: true,
            }
        case "showContent":
            return {
                ...state,
                showContent: true,
            }
        case "nextStory":
            currentStoryNum = slides[state.slideIndex + 1];
            if (state.storyNum === 21) {
                return {
                    ...state
                }
            }
            if (state.storyNum === 0 || state.showContent) {
                return {
                    ...state,
                    slideIndex: state.slideIndex + 1,
                    storyNum: currentStoryNum,
                    storyContent: storyContents[currentStoryNum],
                    showContent: false,
                    showStats: false,
                    statsShowed: false,
                    direction: 1,
                }
            } else {
                return {
                    ...state,
                    showContent: true,
                }
            }

        case "prevStory":
            currentStoryNum = slides[state.slideIndex - 1];
            if (state.storyNum === 0) {
                return {
                    ...state
                }
            }
            else {
                return {
                    ...state,
                    slideIndex: state.slideIndex - 1,
                    storyNum: currentStoryNum,
                    storyContent: storyContents[currentStoryNum],
                    showContent: false,
                    showStats: false,
                    statsShowed: false,
                    direction: 0,
                }
            }

        case "reset":
            return initialState;
        case "setCustomSlide":
            slides.splice(10, 0, 11);
            slides.splice(action.payload.position, 0, 5);
            setCustomSlide(action.payload);
            return {
                ...state,
                personalizedContent: {
                    ...state.personalizedContent,
                    b2c: true,
                    top10: true,
                    totalUsage: action.payload.totalUsage,
                    places: action.payload.places,
                    mostUsedPlace: action.payload.mostUsedPlace,
                    type: action.payload.type,
                }
            }
        case "setPartnerSlides":
            const index = slides.indexOf(3);
            if (index > -1) {
                slides.splice(index, 1);
            }
            slides.splice(10, 0, 13);
            if (action.payload) {
                slides.splice(action.payload.position, 0, 5);
                setCustomSlide(action.payload);
            }
            console.log(slides);
            return {
                ...state,
                personalizedContent: {
                    ...state.personalizedContent,
                    top10: false,
                    b2c: false,
                }
            }
        case "top300":
            slides.splice(10, 0, 11);
            return {
                ...state,
                personalizedContent: {
                    ...state.personalizedContent,
                    top10: false,
                    b2c: true,
                }

            }
        default: {
            return {
                ...state,
            }
        }
    }
}

const initialState = {
    slideIndex: 0,
    storyNum: 0,
    storyContent: {
        year: 0,
        month: '',
        title: '',
        value: 0,
        users: 0,
        chargers: 0,
        positions: 0,
        fleet: 0,
    },
    lang: 'hu',
    showStats: false,
    statsShowed: false,
    showContent: false,
    showContentDelay: 4000,
    showContentShortDelay: 2000,
    direction: 1,
    personalizedContent: {
        b2c: true,
        top10: false,
        totalUsage: 0,
        places: 0,
        mostUsedPlace: "",
        type: ""
    }
}

export const StoryContext = createContext(null);

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const leftKeyPressed = useKeyPress("ArrowLeft");
    const rightKeyPressed = useKeyPress("ArrowRight");

    useEffect(() => {
        // mobile vertical height hack
        let vh = window.innerHeight * 0.01;
        document.documentElement?.style.setProperty('--height', `${vh}px`);
    }, []);

    useEffect(() => {
        if (leftKeyPressed) {
            dispatch({type: 'prevStory'});
        } else if (rightKeyPressed) {
            dispatch({type: 'nextStory'});
        }
        // eslint-disable-next-line
    }, [leftKeyPressed, rightKeyPressed])

    const contextValue = useMemo(() => {
        return {state, dispatch};
    }, [state, dispatch]);

    return (
        <Router>
            <StoryContext.Provider
                value={contextValue}>
                <Switch>
                    <Route exact path="/top300"
                           children={<Top300/>}/>
                    <Route exact path="/for-partners"
                           children={<ForPartners/>}/>
                    <Route path="/:id" children={<CustomStories/>}/>
                    <Route path="/" children={<Slideshow/>}/>
                </Switch>
            </StoryContext.Provider>
        </Router>
    );
}

export default App;
