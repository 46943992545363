import {CSSTransition, TransitionGroup} from "react-transition-group";
import React, {useContext} from "react";
import {StoryContext} from "../App";
import Main from "../pages/main/Main";
import Story from "../pages/story/Story";
import Story2 from "../pages/story/Story2";
import Story3 from "../pages/story/Story3";
import Story4 from "../pages/story/Story4";
import Story5 from "../pages/story/Story5";
import Story6 from "../pages/story/Story6";
import Story7 from "../pages/story/Story7";
import Story8 from "../pages/story/Story8";
import Story9 from "../pages/story/Story9";
import Story10 from "../pages/story/Story10";
import Story11 from "../pages/story/Story11";
import Story12 from "../pages/story/Story12";
import Story13 from "../pages/story/Story13";
import Story14 from "../pages/story/Story14";
import Story15 from "../pages/story/Story15";
import Story16 from "../pages/story/Story16";
import Story17 from "../pages/story/Story17";
import Story18 from "../pages/story/Story18";
import Story19 from "../pages/story/Story19";
import Story20 from "../pages/story/Story20";
import Share from "../pages/share/Share";
import {useSwipeable} from "react-swipeable";

const Slideshow = () => {
    const {state, dispatch} = useContext(StoryContext);
    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            let className = eventData.event.target.parentNode.className;
            if (!className.startsWith('card') && !className.startsWith('swiper')) {
                dispatch({type: 'nextStory'});
            }
        },
        onSwipedRight: (eventData) => {
            let className = eventData.event.target.parentNode.className;
            if (!className.startsWith('card') && !className.startsWith('swiper')) {
                dispatch({type: 'prevStory'})
            }
        },
    });

    const allSlides = [
        <Main/>,
        <Story/>,
        <Story2/>,
        <Story3/>,
        <Story4/>,
        <Story5/>,
        <Story6/>,
        <Story7/>,
        <Story8/>,
        <Story9/>,
        <Story10/>,
        <Story11/>,
        <Story12/>,
        <Story13/>,
        <Story14/>,
        <Story15/>,
        <Story16/>,
        <Story17/>,
        <Story18/>,
        <Story19/>,
        <Story20/>,
        <Share/>
    ];

    // const leftTap = () => {
    //     console.log('left tap');
    // }
    //
    // const rightTap = () => {
    //     console.log('right tap');
    // }

    return (
        <TransitionGroup className={`overflow-hidden h-100 w-100`}
                         childFactory={
                             child => React.cloneElement(child,
                                 {classNames: state.direction === 1 ? 'fade-right' : 'fade-left'})}>

            <CSSTransition
                key={state.storyNum}
                timeout={1000}
                unmountOnExit
            >
                <div className={'h-100 w-100'} {...handlers}>
                    {/*<div className={'tap-area'}>*/}
                    {/*    <div className={`h-100`} onClick={()=>leftTap()}/>*/}
                    {/*    <div className={`h-100`} onClick={()=>rightTap()}/>*/}
                    {/*</div>*/}
                    {allSlides[state.storyNum]}
                </div>
            </CSSTransition>

        </TransitionGroup>
    )
}

export default Slideshow;