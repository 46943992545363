import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/green/stats.svg";
import logo from "../../assets/logo/parkl-logo-green.svg";
import {StoryContext} from "../../App";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const Story10 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentShortDelay);

    return (
        <div className={`bg-black container`}>

            <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                <Pager logo={logo}/>
                <StoryHolder color={'white'}>

                {state.showContent && (
                    <div className={'content-wrapper'}>
                        {!state.showStats && (
                            <Fade bottom={!state.statsShowed}>
                                    <div className={`content txt-white`}>
                                        {state.lang === 'hu' && (
                                            <>
                                                <h1>A Parkl <span className={'txt-green'}>Business</span> szolgáltatás
                                                    bemutatkozik</h1>
                                                <div className={'card-container txt-dark'}>
                                                    <Fade delay={1000}>
                                                        <div className={`card`}>
                                                            <h2>Parkl OFFICE</h2>
                                                            Parkl Office segíti az
                                                            irodaházak dolgozói- és vendégparkolásainak
                                                            zökkenőmentes kezelését.
                                                        </div>
                                                    </Fade>
                                                    <Fade delay={2000}>
                                                        <div className={`card`}>
                                                            <h2>Parkl FLEET</h2>
                                                            A Parkl Fleet lehetővé teszi a Parkl szolgáltatások
                                                            használatát és kezelését céges flották számára.
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </>
                                        )}
                                        {state.lang !== 'hu' && (
                                            <>
                                                <h1>We introduce our new solution: Parkl
                                                    <span className={'txt-green'}> Business</span></h1>
                                                <div className={'card-container txt-dark'}>
                                                    <Fade delay={1000}>
                                                        <div className={`card`}>
                                                            <h2>Parkl OFFICE</h2>
                                                            Parkl Office helps to handle the parkings and chargings of
                                                            office workers and the companies’ guests.
                                                        </div>
                                                    </Fade>
                                                    <Fade delay={2000}>
                                                        <div className={`card`}>
                                                            <h2>Parkl FLEET</h2>
                                                            Easy access and seamless payment of parking and e-charging
                                                            services for every employee in one app.
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </>
                                        )}
                                    </div>
                            </Fade>
                        )}
                        {state.showStats && (
                            <StoryStats color={'white'}/>
                        )}
                    </div>
                )}
                </StoryHolder>

                <Stats
                    switchIcon={switchIcon}
                    statsIcon={statsIcon}
                />
            </div>
        </div>
    );
}

export default Story10;