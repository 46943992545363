import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-purple.svg";
import switchIcon from "../../assets/icons/switch.svg";
import statsIcon from "../../assets/icons/purple/pin.svg";
import pin from "../../assets/icons/purple/pin.svg";
import calendar from "../../assets/icons/purple/calendar.svg";
import clock from "../../assets/icons/purple/clock.svg";
import timer from "../../assets/icons/purple/timer.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC9-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC9-P.mp4';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const Story9 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo} color={'dark'}/>
                    <StoryHolder color={'dark'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <Fade bottom={!state.statsShowed}>
                                    <div className={'w-100'}>
                                            <div className={`content txt-dark`}>
                                                {state.lang === 'hu' && (
                                                    <>
                                                        <h1>Elindul a <span
                                                            className={'txt-purple'}>közterületi parkolás</span></h1>
                                                        A Parkl felhasználók száma már 35 ezer.
                                                        <Swiper
                                                            autoplay={{
                                                                delay: 2000,
                                                                disableOnInteraction: false
                                                            }}
                                                            pagination={{
                                                                clickable: true
                                                            }}
                                                            className={'w-100'}
                                                            spaceBetween={30}
                                                        >
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={calendar} alt={'calendar-logo'}/>
                                                                        <h2>A legtöbben csütörtökön parkolnak.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={timer} alt={'calendar-logo'}/>
                                                                        <h2>Egy átlagos utcai parkolás hossza 1 óra 36
                                                                            perc.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={clock} alt={'calendar-logo'}/>
                                                                        <h2>A legtöbben reggel 8 és 12 óra között
                                                                            parkolnak.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={pin} alt={'calendar-logo'}/>
                                                                        <h2>A legforgalmasabb kerület a XIII.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        </Swiper>
                                                    </>
                                                )}
                                                {state.lang !== 'hu' && (
                                                    <>
                                                        <h1><span className={'txt-purple'}>
                                                            On-street parking</span> launches</h1>
                                                        There are already 35 000 Parkl users.
                                                        <Swiper
                                                            autoplay={{
                                                                delay: 2000,
                                                                disableOnInteraction: false
                                                            }}
                                                            pagination={{
                                                                clickable: true
                                                            }}
                                                            className={'w-100'}
                                                            spaceBetween={30}
                                                        >
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={calendar} alt={'calendar-logo'}/>
                                                                        <h2>Most people park on Thursday.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={timer} alt={'calendar-logo'}/>
                                                                        <h2>The average length of a street parking
                                                                            session is 1 hour 36 minutes.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={clock} alt={'calendar-logo'}/>
                                                                        <h2>Most people park between 8 and 12 in the
                                                                            morning.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={`card flex flex-align-center`}>
                                                                        <img src={pin} alt={'calendar-logo'}/>
                                                                        <h2>The busiest district is the thirteenth.</h2>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        </Swiper>
                                                    </>
                                                )}
                                            </div>
                                    </div>
                                </Fade>
                            )}
                            {state.showStats && (
                                <StoryStats color={'dark'}/>
                            )}
                        </div>
                    )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                        color={'dark'}
                    />
                </div>
            </div>
        </>
    );
}

export default Story9;