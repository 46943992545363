import Fade from "react-reveal/Fade";
import calendar from "../../assets/icons/green/calendar.svg";
import park_green from "../../assets/icons/green/park.svg";
import bolt_green from "../../assets/icons/green/bolt.svg";

const Top10Content = ({lang, content}) => {
    return (

        <>
            {lang === 'hu' && (
                <h1>Benne vagy a <span
                    className={'txt-white'}>top 10-ben!</span> Amióta
                    velünk
                    vagy:</h1>
            )}
            {lang !== 'hu' && (
                <h1>You are in the <span
                    className={'txt-white'}>top 10!</span> Since you have joined us:</h1>
            )}
            <div className={'card-container'}>
                <Fade delay={1000}>
                    <div className={`card flex flex-align-center`}>
                        <img src={calendar} alt={''}/>
                        <div className={`flex flex-col`}>
                            <h2>
                                {content.totalUsage}
                            </h2>
                            {content.type !== 'charging' && (
                                <span className={'avenir'}>
                                    {lang === 'hu' && (
                                        <>
                                            órát parkoltál velünk
                                        </>
                                    )}
                                    {lang !== 'hu' && (
                                        <>
                                            your total parking time in hours
                                        </>
                                    )}
                                </span>
                            )}
                            {content.type === 'charging' && (
                                <span className={'avenir'}>
                                    {lang === 'hu' && (
                                        <>
                                            árammal töltötted fel autódat
                                        </>
                                    )}
                                    {lang !== 'hu' && (
                                        <>
                                            the amount of electricity you consumed
                                        </>
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </Fade>
                <Fade delay={1500}>
                    <div className={`card flex flex-align-center`}>
                        <img src={park_green} alt={''}/>
                        <div className={`flex flex-col`}>
                            <h2>
                                {content.places}
                            </h2>
                            {content.type === 'street' && (
                                <span className={'avenir'}>
                                    {lang === 'hu' && (
                                        <>
                                            különböző zónában
                                        </>
                                    )}
                                    {lang !== 'hu' && (
                                        <>
                                            the number of zones you've parkd at
                                        </>
                                    )}
                                </span>
                            )}
                            {content.type === 'parking' && (
                                <span className={'avenir'}>
                                    {lang === 'hu' && (
                                        <>
                                            helyszínen
                                        </>
                                    )}
                                    {lang !== 'hu' && (
                                        <>
                                            the number of locations you've parked at
                                        </>
                                    )}
                                </span>
                            )}
                            {content.type === 'charging' && (
                                <span className={'avenir'}>

                                    {lang === 'hu' && (
                                        <>
                                            töltöponton
                                        </>
                                    )}
                                    {lang !== 'hu' && (
                                        <>
                                            the number of charging points you used
                                        </>
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </Fade>
                <Fade delay={2000}>
                    <div className={`card flex flex-align-center`}>
                        <img src={bolt_green} alt={''}/>
                        <div className={`flex flex-col`}>
                            {(content.type === 'parking' || content.type === 'charging') && lang === 'hu' && (
                                <span className={'avenir'}>Legtöbbször</span>
                            )}
                            {lang === 'en' && (
                                <span className={'avenir'}>Most of the time at</span>
                            )}
                            <h2>
                                {content.mostUsedPlace}
                            </h2>
                            {content.type === 'street' && lang==='hu' &&(
                                <span className={'avenir'}>
                                    számúban a legtöbbször
                                </span>
                            )}
                        </div>
                    </div>
                </Fade>
            </div>
        </>
    );
}

export default Top10Content;
