import {StoryContext} from "../../App";
import {useContext, useEffect, useState} from "react";
import Fade from 'react-reveal/Fade';
import Odometer from "react-odometerjs";
import user_white from "../../assets/icons/white/user.svg";
import park_white from "../../assets/icons/white/park.svg";
import bolt_white from "../../assets/icons/white/bolt.svg";
import car_white from "../../assets/icons/white/car.svg";
import user_dark from "../../assets/icons/user.svg";
import park_dark from "../../assets/icons/park.svg";
import bolt_dark from "../../assets/icons/bolt.svg";
import car_dark from "../../assets/icons/car.svg";

const StoryStats = ({color}) => {
    const {state} = useContext(StoryContext);
    const [countValues, setCountValues] = useState({
        users: 0,
        positions: 0,
        chargers: 0,
        fleet: 0,
    });

    const getIcon = (forElement) => {
        switch (color) {
            case 'white':
                if (forElement==='user'){
                    return user_white;
                } else if (forElement==='park'){
                    return park_white;
                } else if (forElement==='bolt'){
                    return bolt_white;
                } else if (forElement==='car'){
                    return car_white;
                }
                break;
            case 'dark':
                if (forElement==='user'){
                    return user_dark;
                } else if (forElement==='park'){
                    return park_dark;
                } else if (forElement==='bolt'){
                    return bolt_dark;
                } else if (forElement==='car'){
                    return car_dark;
                }
                break;
            default:
                if (forElement==='user'){
                    return user_dark;
                } else if (forElement==='park'){
                    return park_dark;
                } else if (forElement==='bolt'){
                    return bolt_dark;
                } else if (forElement==='car'){
                    return car_dark;
                }
        }
    }

    useEffect(()=>{
        setCountValues({
            users: state.storyContent.users,
            positions: state.storyContent.positions,
            chargers: state.storyContent.chargers,
            fleet: state.storyContent.fleet,
        });
    }, [state.storyContent])

    return (
      <Fade>
          <div className={`content txt-${color}`}>
              <div className={'story-stats-row'}>
                  <img src={getIcon('user')} alt={'logo'} className={'story-stats-icon'}/>
                  <div className={'story-stats-info'}>
                      <h1>
                          <Odometer
                              format="( ddd)"
                              duration={ 1500 }
                              value={countValues.users}/>
                      </h1>
                      <span className={'txt-uppercase'}>{state.lang==='hu'? 'Felhasználó': 'Users'}</span>
                  </div>
              </div>
              <div className={`bordered bg-${color}`}/>
              <div className={'story-stats-row'}>
                  <img src={getIcon('park')} alt={'logo'} className={'story-stats-icon'}/>
                  <div className={'story-stats-info'}>
                      <h1>
                          <Odometer
                              format="( ddd)"
                              duration={ 1500 }
                              value={countValues.positions}/>
                      </h1>
                      <span className={'txt-uppercase'}>{state.lang==='hu'? 'Parkolóhely': 'Parking spots'}</span>
                  </div>
              </div>
              <div className={`bordered bg-${color}`}/>
              <div className={'story-stats-row'}>
                  <img src={getIcon('bolt')} alt={'logo'} className={'story-stats-icon'}/>
                  <div className={'story-stats-info'}>
                      <h1>
                          <Odometer
                              format="( ddd)"
                              duration={ 1500 }
                              value={countValues.chargers}/>
                      </h1>
                      <span className={'txt-uppercase'}>{state.lang==='hu'? 'Töltőpont': 'Charging stations'}</span>
                  </div>
              </div>
              <div className={`bordered bg-${color}`}/>
              <div className={'story-stats-row'}>
                  <img src={getIcon('car')} alt={'logo'} className={'story-stats-icon'}/>
                  <div className={'story-stats-info'}>
                      <h1>
                          <Odometer
                              format="( ddd)"
                              duration={ 1500 }
                              value={countValues.fleet}/>
                      </h1>
                      <span className={'txt-uppercase'}>{state.lang==='hu'? 'Parkl Business tag': 'Fleet'}</span>
                  </div>
              </div>
          </div>
      </Fade>
    );
}

export default StoryStats;