import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-purple.svg";
import switchIcon from "../../assets/icons/switch.svg";
import statsIcon from "../../assets/icons/purple/pin.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";

import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC4-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC4-P.mp4';

const Story4 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});

    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo} color={'dark'}/>
                    <StoryHolder color={'dark'}>

                        {state.showContent && (
                            <div className={'content-wrapper'}>
                                {!state.showStats && (
                                    <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-dark`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>Zárt téri parkolóink száma átlépi a <span
                                                        className={'txt-purple'}>huszat</span></h1>
                                                    Legtöbben parkolóházak, de egyre több irodaház
                                                    és szálloda is csatlakozik.
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>The number of our parking locations exceeds <span
                                                        className={'txt-purple'}>twenty.</span></h1>
                                                    Mostly car parks, but also office buildings and hotels joined us.
                                                </>
                                            )}
                                        </div>
                                    </Fade>
                                )}
                                {state.showStats && (
                                    <StoryStats color={'dark'}/>
                                )}
                            </div>
                        )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                        color={'dark'}
                    />
                </div>
            </div>
        </>
    );
}

export default Story4;