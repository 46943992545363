import {useContext, useEffect, useState} from "react";
import Slideshow from "../Slideshow";
import {StoryContext} from "../../App";

const Top300 = () => {
    const {dispatch} = useContext(StoryContext);
    const [loaded, setLoaded] = useState(false);
    useEffect(()=>{
        dispatch({type: 'top300'})
        setLoaded(true);
    }, [dispatch])

    return (
        <>
            {loaded && (
                <>
                    <Slideshow/>
                </>
            )}
        </>
    )
}
export default Top300;