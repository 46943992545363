import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-white.svg";
import switchIcon from "../../assets/icons/white/switch.svg";
import statsIcon from "../../assets/icons/white/pin.svg";
import {StoryContext} from "../../App";
import {useMediaQuery} from "react-responsive";
import Fade from "react-reveal/Fade";
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

const video_l = 'https://parklcdn.azureedge.net/web/slide_videos/SC14-L.mp4';
const video_p = 'https://parklcdn.azureedge.net/web/slide_videos/SC14-P.mp4';

const Story14 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentShortDelay);

    const isLargeScreen = useMediaQuery({query: '(min-width: 900px)'});


    return (
        <>
            <div className={'video-content'}>
                <video src={isLargeScreen ? video_l : video_p} autoPlay={true} playsInline={true} muted={true}
                       className={'video'}/>
            </div>
            <div className={`container`}>

                <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                    <Pager logo={logo}/>
                    <StoryHolder color={'white'}>

                        {state.showContent && (
                            <div className={'content-wrapper'}>
                                {!state.showStats && (
                                    <Fade bottom={!state.statsShowed}>
                                        <div className={`content txt-white`}>
                                            {state.lang === 'hu' && (
                                                <h1>De aztán...</h1>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <h1>But then...</h1>
                                            )}
                                        </div>
                                    </Fade>
                                )}
                                {state.showStats && (
                                    <StoryStats color={'white'}/>
                                )}
                            </div>
                        )}
                    </StoryHolder>

                    <Stats
                        switchIcon={switchIcon}
                        statsIcon={statsIcon}
                    />
                </div>
            </div>
        </>
    );
}

export default Story14;