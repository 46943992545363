import Fade from "react-reveal/Fade";
import calendar from "../../assets/icons/green/calendar.svg";
import Odometer from "react-odometerjs";
import park_green from "../../assets/icons/green/park.svg";
import bolt_green from "../../assets/icons/green/bolt.svg";
import {useEffect, useState} from "react";

const Top300Content = ({lang}) => {
    const [countValues, setCountValues] = useState({
        offStreetHours: 0,
        onStreetHours: 0,
        chargeCount: 0,
    });

    useEffect(() => {
        setCountValues({
            onStreetHours: 95996,
            offStreetHours: 307170,
            chargeCount: 68312,
        });
    }, []);

    return (
        <>
            {lang === 'hu' && (
                <>
                    <h1>Benne vagy a <span
                        className={'txt-white'}>top 300-ban!</span> Amióta
                        velünk
                        vagytok:</h1>
                    <div className={'card-container'}>
                        <Fade delay={1000}>
                            <div className={`card flex flex-align-center`}>
                                <img src={calendar} alt={'calendar-logo'}/>
                                <div className={`flex flex-col`}>
                                    <h2>
                                        <Odometer
                                            format="( ddd)"
                                            duration={1500}
                                            value={countValues.offStreetHours}/>
                                    </h2>
                                    órát parkoltatok velünk zárt téren
                                </div>
                            </div>
                        </Fade>
                        <Fade delay={1500}>
                            <div className={`card flex flex-align-center`}>
                                <img src={park_green} alt={'calendar-logo'}/>
                                <div className={`flex flex-col`}>
                                    <h2>
                                        <Odometer
                                            format="( ddd)"
                                            duration={1500}
                                            value={countValues.onStreetHours}/>
                                    </h2>
                                    órát parkoltatok velünk közterületen
                                </div>
                            </div>
                        </Fade>
                        <Fade delay={2000}>
                            <div className={`card flex flex-align-center`}>
                                <img src={bolt_green} alt={'calendar-logo'}/>
                                <div className={`flex flex-col`}>
                                    <h2>
                                        <Odometer
                                            format="( ddd)"
                                            duration={1500}
                                            value={countValues.chargeCount}/>

                                    </h2>
                                    kWh áramot fogyasztottatok elektromos töltőinken
                                </div>
                            </div>
                        </Fade>
                    </div>
                </>
            )}
            {lang !== 'hu' && (
                <>
                    <h1>You are in our <span
                        className={'txt-white'}>top 300 users!</span> SINCE THE 300 OF
                        YOU HAVE JOINED US:</h1>
                    <div className={'card-container'}>
                        <Fade delay={1000}>
                            <div className={`card flex flex-align-center`}>
                                <img src={calendar} alt={'calendar-logo'}/>
                                <div className={`flex flex-col`}>
                                    <h2>
                                        <Odometer
                                            format="( ddd)"
                                            duration={1000}
                                            value={countValues.offStreetHours}/>
                                    </h2>
                                    hours you’ve parked off-street
                                </div>
                            </div>
                        </Fade>
                        <Fade delay={1500}>
                            <div className={`card flex flex-align-center`}>
                                <img src={park_green} alt={'calendar-logo'}/>
                                <div className={`flex flex-col`}>
                                    <h2>
                                        <Odometer
                                            format="( ddd)"
                                            duration={1000}
                                            value={countValues.onStreetHours}/>
                                    </h2>
                                    hours you’ve parked on-street
                                </div>
                            </div>
                        </Fade>
                        <Fade delay={2000}>
                            <div className={`card flex flex-align-center`}>
                                <img src={bolt_green} alt={'calendar-logo'}/>
                                <div className={`flex flex-col`}>
                                    <h2>
                                        <Odometer
                                            format="( ddd)"
                                            duration={1000}
                                            value={countValues.chargeCount}/>
                                    </h2>
                                    kWh electricity consumed on our electric chargers
                                </div>
                            </div>
                        </Fade>
                    </div>
                </>
            )}
        </>
    );
}

export default Top300Content;
