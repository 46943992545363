import {useMediaQuery} from "react-responsive";
import {useContext} from "react";
import {StoryContext} from "../../App";
import './StoryHolder.scss';

const StoryHolder = (props) => {
    const {state, dispatch} = useContext(StoryContext);
    const isSmScreen = useMediaQuery({query: '(max-width: 383px)'});

    return (
        <div className={`${isSmScreen ? 'w-100' : 'story-holder'}`}>
            {!isSmScreen && (
                <span
                    className={`icon icon-arrow ${state.storyNum > 1 ? `icon-arrow-left-${props.color}` : ``}`}
                    onClick={()=>dispatch({type: 'prevStory'})}
                />
            )}
            {props.children}
            {!isSmScreen && (
                <span className={`icon icon-arrow icon-arrow-right-${props.color}`}
                      onClick={()=>dispatch({type: 'nextStory'})}
                />
            )}
        </div>
    );
}
export default StoryHolder;