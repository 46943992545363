import './Story.scss';
import Stats from "../../components/stats/Stats";
import Pager from "../../components/pager/Pager";
import {useContext} from "react";
import StoryStats from "../../components/storyStats/StoryStats";
import logo from "../../assets/logo/parkl-logo-dark.svg";
import switchIcon from "../../assets/icons/switch.svg";
import statsIcon from "../../assets/icons/pin.svg";
import Fade from "react-reveal/Fade";
import {StoryContext} from "../../App";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import StoryHolder from "../../components/story/storyHolder";
import useShowContent from "../../components/useShowContent";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const Story3 = () => {
    const {state} = useContext(StoryContext);

    useShowContent(state.showContentShortDelay);

    return (
        <div className={`bg-green container`}>

            <div className={`wrapper wrapper-story ${state.showStats ? `wrapper-stats` : ``}`}>
                <Pager logo={logo} color={'dark'}/>
                <StoryHolder color={'white'}>

                    {state.showContent && (
                        <div className={'content-wrapper'}>
                            {!state.showStats && (
                                <Fade bottom={!state.statsShowed}>
                                    <div className={'w-100'}>
                                        <div className={`content txt-dark`}>
                                            {state.lang === 'hu' && (
                                                <>
                                                    <h1>A fogadtatás jól sikerült</h1>
                                                    <Fade delay={1000}>
                                                        <Swiper
                                                            autoplay={{
                                                                delay: 4000,
                                                                disableOnInteraction: false
                                                            }}
                                                            pagination={{
                                                                clickable: true
                                                            }}
                                                            className={'w-100'}
                                                            spaceBetween={30}
                                                        >
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={'card'}>
                                                                        <h2>Szuper kényelmes, hogy nem kell jegyet
                                                                            húznom!
                                                                            Hajrá,
                                                                            remélem egyre több
                                                                            helyen lesz Parkl.</h2>
                                                                        <p className={'minuscule'}>Appstore Review</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={'card'}>
                                                                        <h2>Bárcsak mindenhol ilyen egyszerű lenne, és
                                                                            nem kéne
                                                                            parkolócetlikkel
                                                                            agyvérzést kapni Mari néni mögött, aki az
                                                                            utolsó
                                                                            salátára
                                                                            gyűrt ezresét
                                                                            próbálja huszadszorra is belegyűrni a
                                                                            parkolóautomatába.</h2>
                                                                        <p className={'minuscule'}>Horváth András -
                                                                            blogger,
                                                                            youtuber</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={'card'}>
                                                                        <h2>Budapestről indul a parkolás Ubere?
                                                                            Itt egy magyar app, amivel itt is
                                                                            parkolhatsz, ahol
                                                                            amúgy
                                                                            nem.</h2>
                                                                        <p className={'minuscule'}>totalcar.hu</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        </Swiper>
                                                    </Fade>
                                                </>
                                            )}
                                            {state.lang !== 'hu' && (
                                                <>
                                                    <h1>It was warmly welcome</h1>
                                                    <Fade delay={1000}>
                                                        <Swiper
                                                            autoplay={{
                                                                delay: 4000,
                                                                disableOnInteraction: false
                                                            }}
                                                            pagination={{
                                                                clickable: true
                                                            }}
                                                            className={'w-100'}
                                                            spaceBetween={30}
                                                        >
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={'card'}>
                                                                        <h2>It is super comfortable that I don’t need to
                                                                            draw a ticket anymore! I hope there will be
                                                                            more and more Parkl locations
                                                                            available.</h2>
                                                                        <p className={'minuscule'}>Appstore Review</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={'card'}>
                                                                        <h2>The app is quick, easy and painless.
                                                                            Features like the customizable reminder,
                                                                            auto-renewal or instant parking tops all the
                                                                            competitors.</h2>
                                                                        <p className={'minuscule'}>Appstore Review</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className={'card-container'}>
                                                                    <div className={'card'}>
                                                                        <h2>Love the off-street parking with opening the
                                                                            parking gate barrier right from the app.
                                                                            Keep up the good work!</h2>
                                                                        <p className={'minuscule'}>Appstore Review</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        </Swiper>
                                                    </Fade>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Fade>
                            )}
                            {state.showStats && (
                                <StoryStats color={'dark'}/>
                            )}
                        </div>
                    )}
                </StoryHolder>

                <Stats
                    switchIcon={switchIcon}
                    statsIcon={statsIcon}
                    color={'dark'}
                />
            </div>
        </div>
    );
}

export default Story3;